<template>
  <div style="background: rgba(242, 242, 242, 1)">
    <div class="page-content">
      <Tabs :value="$route.name" @on-click="changePage" class="centertabs">
        <TabPane :index="1" label="通用文章" name="normal" />
        <TabPane :index="2" label="精读文章" name="delicate" v-if="laun==='cn'" />
      </Tabs>
      <router-view :laun="laun" />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    laun() {
      return this.$store.getters.getLaun;
    },
  },
  methods: {
    changePage(newPage) {
      this.$router.replace(newPage);
    },
  },
  beforeMount() {},
};
</script>

<style scoped>
.page-content {
  width: 1000px;
  margin: 0 auto 0;
  border: 1px solid rgba(228, 228, 228, 1);
  background: #fff;
  min-height: 86vh;
}
</style>